<script setup lang="ts">
import * as Sentry from "@sentry/vue";
import { isAuthError } from "@supabase/auth-js";
import libphonenumber from "google-libphonenumber";
import type { Database } from "~/types/supabase";

definePageMeta({
  layout: "empty",
});
useHead({
  title: "Login",
});

const config = useRuntimeConfig();
const route = useRoute();
const supabase = useSupabaseClient<Database>();
const user = useSupabaseUser();

const authErrorMessage = ref("");
const email = ref("");
const emailError = ref("");
const emailMessage = ref("");
const oauthMessage = ref("");
const phone = ref("");
const phoneError = ref("");
const phoneMessage = ref("");
const phoneVerification = ref("");
const phoneVerificationError = ref("");
const isSubmitting = ref(false);
const showPhoneVerification = ref(false);
const redirectUrl = getAuthenticatedRedirectPath();

const authError = route.query.auth_error;

// If the page was loaded with authentication errors provided from the callback URL, read that information in to show the user an error message
if (typeof authError !== "undefined" && authError === "1") {
  const authProvider = route.query.auth_provider as
    | "azure"
    | "email"
    | undefined;
  const authErrorType = route.query.auth_error_type as
    | "expired"
    | "generic"
    | "missing_email"
    | undefined;

  switch (authErrorType) {
    case "missing_email":
      // Tailor the message based on the provider
      if (authProvider === "azure") {
        authErrorMessage.value =
          "Please verify that your email address is saved to your Microsoft account's contact information before trying to log in again. If you continue to have issues, please contact support.";
      } else {
        authErrorMessage.value =
          "Please try to log in again. If you continue to have issues, please contact support.";
      }
      break;

    case "expired":
      if (authProvider === "email") {
        authErrorMessage.value =
          "Your log in link has expired, please enter your email address again to request get new link. If you continue to have issues, please contact support.";
      } else {
        authErrorMessage.value =
          "Please try to log in again. If you continue to have issues, please contact support.";
      }

      break;

    case "generic":
    default:
      authErrorMessage.value =
        "Please try to log in again. If you continue to have issues, please contact support.";

      break;
  }
}

async function signInWithAzure() {
  if (isSubmitting.value) return;

  isSubmitting.value = true;
  oauthMessage.value = "Signing in";

  try {
    await supabase.auth.signInWithOAuth({
      provider: "azure",
      options: {
        scopes: "email profile offline_access",
      },
    });
  } catch (error) {
    Sentry.captureException(error);

    oauthMessage.value = "Well, that didn't work. Try again?";
  } finally {
    isSubmitting.value = false;
  }
}

async function signInWithEmail() {
  if (isSubmitting.value) return;

  isSubmitting.value = true;
  emailError.value = "";
  emailMessage.value = "Sending you a one time password";

  if (email.value.trim() === "") {
    emailError.value = "Please enter your email address.";
    emailMessage.value = "";
    isSubmitting.value = false;

    return;
  }

  try {
    const { error } = await supabase.auth.signInWithOtp({
      email: email.value.trim(),
      options: {
        emailRedirectTo: config.public.baseUrl,
        shouldCreateUser: false,
      },
    });

    if (error) throw error;

    emailMessage.value = "Check your inbox for your log in link!";
  } catch (error) {
    Sentry.captureException(error);

    let errorMessage =
      "Well, that didn't work. Maybe try a different email address?";

    if (isAuthError(error)) {
      // There are some error states we can show a "better" message for without being too much of an info leak
      if (error.status === 422) {
        errorMessage = "Only confirmed accounts can log in with an email link.";
      } else if (error.status === 429) {
        errorMessage =
          "You've tried signing in too many times, please wait a minute to try again.";
      }
    }

    emailMessage.value = errorMessage;
  } finally {
    isSubmitting.value = false;
  }
}

async function signInWithPhone() {
  if (isSubmitting.value) return;

  isSubmitting.value = true;
  phoneError.value = "";
  phoneMessage.value = "Sending you a verification code";

  if (phone.value.trim() === "") {
    phoneError.value = "Please enter your phone number.";
    phoneMessage.value = "";
    isSubmitting.value = false;

    return;
  }

  const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
  let formattedPhone: string | undefined = undefined;

  // Supabase requires phone numbers to be submitted in E164 format
  try {
    formattedPhone = phoneUtil.format(
      phoneUtil.parse(phone.value.trim(), "US"),
      libphonenumber.PhoneNumberFormat.E164
    );
  } catch (e) {
    Sentry.captureException(e);

    phoneError.value =
      "Your phone number could not be processed, check it for errors and try again.";
    phoneMessage.value = "";
    isSubmitting.value = false;

    return;
  }

  try {
    const { error } = await supabase.auth.signInWithOtp({
      phone: formattedPhone,
      options: {
        shouldCreateUser: false,
        channel: "sms",
      },
    });

    if (error) throw error;

    phoneMessage.value = "Check your text messages for your verification code!";
    showPhoneVerification.value = true;
  } catch (error) {
    Sentry.captureException(error);

    let errorMessage =
      "Well, that didn't work. Maybe try a different phone number?";

    if (isAuthError(error)) {
      // There are some error states we can show a "better" message for without being too much of an info leak
      if (error.status === 422) {
        errorMessage =
          "Only confirmed accounts can log in with with a phone number.";
      } else if (error.status === 429) {
        errorMessage =
          "You've tried signing in too many times, please wait a minute to try again.";
      }
    }

    phoneMessage.value = errorMessage;
  } finally {
    isSubmitting.value = false;
  }
}

async function verifyCodeForPhone() {
  if (isSubmitting.value) return;

  isSubmitting.value = true;
  phoneVerificationError.value = "";
  phoneMessage.value = "Verifying code";

  if (phoneVerification.value.trim() === "") {
    phoneVerificationError.value = "Please enter your verification code.";
    phoneMessage.value = "";
    isSubmitting.value = false;

    return;
  }

  const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
  let formattedPhone: string | undefined = undefined;

  // Supabase requires phone numbers to be submitted in E164 format
  try {
    formattedPhone = phoneUtil.format(
      phoneUtil.parse(phone.value.trim(), "US"),
      libphonenumber.PhoneNumberFormat.E164
    );
  } catch (e) {
    Sentry.captureException(e);

    phoneVerificationError.value =
      "Your phone number could not be processed, check it for errors and try again.";
    phoneMessage.value = "";
    isSubmitting.value = false;

    return;
  }

  try {
    const { error } = await supabase.auth.verifyOtp({
      phone: formattedPhone!,
      token: phoneVerification.value.trim(),
      type: "sms",
    });

    if (error) throw error;

    phoneMessage.value = "You've been logged in!";
    showPhoneVerification.value = true;
  } catch (error) {
    Sentry.captureException(error);

    phoneMessage.value =
      "Well, that didn't work. Maybe try a different phone number?";
  } finally {
    isSubmitting.value = false;
  }
}

// Automatically redirect this window to the dashboard once the user is authenticated (the immediate flag takes care of redirecting on initial load)
watch(
  user,
  () => {
    if (user.value) {
      return navigateTo(redirectUrl);
    }
  },
  { immediate: true }
);
</script>

<template>
  <div
    class="dark:bg-background-100 dark:text-background-600 flex min-h-screen bg-background-600"
  >
    <div
      class="relative flex flex-1 flex-col justify-center px-6 py-12 lg:w-2/5 lg:flex-none"
    >
      <div class="relative mx-auto w-full max-w-sm">
        <Alert v-if="authErrorMessage" variant="error" class="my-4 p-2">
          <template #title>Could Not Log In</template>
          <template #message>
            <p>{{ authErrorMessage }}</p>
          </template>
        </Alert>
        <!--Nav-->
        <div class="flex w-full items-center justify-between">
          <!--Theme button-->
          <ThemeToggle />
        </div>
        <div>
          <h2 class="text-2xl font-semibold">Let's go!</h2>
          <div v-if="oauthMessage" class="my-4 text-center">
            {{ oauthMessage }}
          </div>

          <!-- Social Sign In Buttons -->
          <div class="flex flex-wrap justify-between gap-4">
            <!-- OAuth buttons -->
            <button
              type="button"
              class="border-muted-300 dark:border-muted-600 nui-focus relative inline-flex grow items-center justify-center gap-2 rounded border px-6 py-4"
              @click="signInWithAzure"
            >
              <Icon name="logos:microsoft-icon" size="2em" />
              <div>Login with Office365</div>
            </button>
          </div>
        </div>

        <!-- 'or' divider -->
        <div class="flex-100 mt-8 flex items-center">
          <hr
            class="border-muted-200 dark:border-muted-700 flex-auto border-t-2"
          />
          <span
            class="text-muted-600 dark:text-muted-300 px-4 font-sans font-light"
          >
            OR
          </span>
          <hr
            class="border-muted-200 dark:border-muted-700 flex-auto border-t-2"
          />
        </div>

        <!-- Email login form -->
        <form class="mt-6" novalidate @submit.prevent="signInWithEmail">
          <div class="mt-5">
            <div>
              <div class="space-y-4">
                <div v-if="emailMessage" class="mt-4 text-center">
                  {{ emailMessage }}
                </div>
                <FormsInput
                  v-model="email"
                  type="email"
                  :input-attrs="{
                    class: 'h-12',
                    disabled: isSubmitting,
                    placeholder: 'Email address',
                    autocomplete: 'email',
                  }"
                  label="Email address"
                  :error="emailError"
                />
              </div>

              <!--Submit-->
              <div class="mt-6">
                <div class="block w-full rounded-md shadow-sm">
                  <ButtonsBase
                    :disabled="isSubmitting"
                    type="submit"
                    color="primary"
                    class="!h-11 w-full"
                  >
                    Let's Go
                  </ButtonsBase>
                </div>
              </div>
            </div>
          </div>
        </form>

        <!-- 'or' divider -->
        <div class="flex-100 mt-8 flex items-center">
          <hr
            class="border-muted-200 dark:border-muted-700 flex-auto border-t-2"
          />
          <span
            class="text-muted-600 dark:text-muted-300 px-4 font-sans font-light"
          >
            OR
          </span>
          <hr
            class="border-muted-200 dark:border-muted-700 flex-auto border-t-2"
          />
        </div>

        <!-- Phone login form -->
        <form
          class="mt-6"
          novalidate
          @submit.prevent="
            () =>
              showPhoneVerification ? verifyCodeForPhone() : signInWithPhone()
          "
        >
          <div class="mt-5">
            <div>
              <div class="space-y-4">
                <div v-if="phoneMessage" class="mt-4 text-center">
                  {{ phoneMessage }}
                </div>
                <FormsInput
                  v-model="phone"
                  type="tel"
                  :input-attrs="{
                    class: 'h-12',
                    disabled: isSubmitting,
                    placeholder: 'Phone number',
                    autocomplete: 'tel',
                  }"
                  label="Phone number"
                  :error="phoneError"
                />
                <FormsInput
                  v-if="showPhoneVerification"
                  v-model="phoneVerification"
                  type="text"
                  :input-attrs="{
                    class: 'h-12',
                    disabled: isSubmitting,
                    placeholder: 'Verification Code',
                    inputmode: 'numeric',
                    maxlength: 6,
                    autocomplete: 'one-time-code',
                  }"
                  label="Verification Code"
                  :error="phoneVerificationError"
                />
              </div>

              <!--Submit-->
              <div class="mt-6">
                <div class="block w-full rounded-md shadow-sm">
                  <ButtonsBase
                    :disabled="isSubmitting"
                    type="submit"
                    color="primary"
                    class="!h-11 w-full"
                  >
                    Let's Go
                  </ButtonsBase>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div
      class="bg-muted-100 dark:bg-muted-900 relative hidden w-0 flex-1 items-center justify-center lg:flex lg:w-3/5"
    >
      <NuxtImg
        src="/goodiemachine.jpg"
        class="absolute top-0 left-0 object-cover w-full h-full"
      />
    </div>
  </div>
</template>
